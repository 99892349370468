import "../scss/pages/Pricing.scss"
import { Link } from "gatsby"

import React from "react"
import SEO from "../components/marketing/seo"
import Header from "../components/marketing/Header/Header.jsx"
import Footer from "../components/marketing/Footer/Footer.jsx"

import PiggyBank from '!svg-react-loader!../../content/assets/icn-piggy-bank.svg?name=PiggyBank'

import MailingListSignup from "../components/marketing/MailingListSignup/MailingListSignup.jsx"



class Pricing extends React.Component {
  openChat = (e) => {
    e.preventDefault()
    window.HubSpotConversations.widget.open()
  }

  render() {
    return (
      <React.Fragment>
        <SEO title={`Hammock - Pricing`}
             description={`Simple service, simple pricing. $12/month or $120/year.`}
             keywords={['tryhammock.com pricing', 'pricing']}
        />

        <Header />

        <div className="pricing-page container">
          <div className="row">
            <div className="pricing-hero col-lg-6 offset-lg-3 col-md-12">
              <PiggyBank className="pricing-hero-icon" />
              <h1>Pricing</h1>
              <p>No credit card required for a 14-day trial! <strong>Try first, decide later.</strong></p>
            </div>
          </div>
        </div>

        <div className="pricing-boxes section grey px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <div className="row pricing-cards">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5 mb-md-0">
                    <div className="pricing-card">
                      <h6 className="section-heading">Pay Monthly</h6>
                      <h3 className="price">$12/month</h3>
                      <h6 className="sub-price">or $144/year</h6>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="pricing-card">
                      <h6 className="section-heading">Pay Yearly</h6>
                      <h3 className="price">$120/year</h3>
                      <h6 className="sub-price">or $10/month</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="feature-list section grey px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3">
                <div className="included">
                  <h5>What's Included?</h5>
                  <ul>
                    <li>Dashboard Insights </li>
                    <li>Up-to-date Runway Calculations</li>
                    <li>Automatic Income Tracking</li>
                    <li>Automatic Expense Tracking </li>
                    <li>Federal Quarterly Tax Estimates</li>
                  </ul>
                </div>

                <div className="coming-soon">
                  <h5>Coming Soon</h5>
                  <ul>
                    <li>Goal Setting</li>
                    <li>State Quarterly Tax Estimates</li>
                    <li>Income & Expense Projections </li>
                    <li>Report Exports</li>
                    <li>… and more!</li>
                  </ul>
                </div>

                <br />

                <a className="btn btn-primary" href="https://app.tryhammock.com/signup" title="Signup for a 14-day FREE Trial">
                  Signup for <strong>FREE</strong> 14-day Trial
                </a>

              </div>
            </div>
          </div>
        </div>




        <div className="have-questions section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>Have Questions?</h2>
                <p>Have questions about pricing, or features? We're here to help!</p>
                <br />

                <a className="btn btn-primary" href="#chat" onClick={this.openChat} title="Signup for a 14-day FREE Trial">
                  Chat With Us
                </a>

              </div>
            </div>
          </div>
        </div>


        <MailingListSignup signupLocation="Feature Page: Expense Tracking"/>

        <Footer />
      </React.Fragment>
    )
  }
}

export default Pricing
